import React, { useState } from "react";
import "./Contact.css";
import axios from "axios";
import pin from "../../images/pin.svg";
import phone from "../../images/phone.svg";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("mail.php", formData)
      .then((response) => {
        console.log("Message send");
      })
      .catch((error) => {
        console.log("Error sending message");
      });
  };

  return (
    <section className="ozslot-contact pad">
      <h1 className="ozslot-contact__header">Contacts</h1>
      <p className="t-24 ozslot-contact__text">
        Our club is located at the Sealuxe Surfers Paradise Central Hotel at
        Ave, Surfers Paradise, QLD, 4217. Club members can use the cozy rooms
        and receive gaming tokens for the casino located in the hotel.
      </p>
      <div className="ozslot-contact__map-container">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3522.760519330507!2d153.42429287438839!3d-28.00122237601533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b9105729d2fbbe5%3A0x3d5ca13af21d1e97!2zOSBGZXJueSBBdmUsIFN1cmZlcnMgUGFyYWRpc2UgUUxEIDQyMTcsINCQ0LLRgdGC0YDQsNC70LjRjw!5e0!3m2!1sru!2spl!4v1728235518864!5m2!1sru!2spl"
          width="1000"
          height="550"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="ozslot-contact__map"
        ></iframe>
      </div>
      <div className="ozslot-contact__adress-container">
        <img src={pin} alt="pin icon" title="pin icon" />
        <p className="t-22">118 High Street, Dunedin, 9016</p>
      </div>
      <div className="ozslot-contact__phone-container">
        <img src={phone} alt="phone icon" title="phone icon" />
        <p className="t-22">+64 3 477 0752</p>
      </div>
      <div className="ozslot-contact__form-container">
        <p className="ozslot-contact__small-header col-white">
          Please fill out the form below to apply for upcoming tournaments.
        </p>
        <p className="t-18 col-white ozslot-contact__small-text">
          After submitting your application, our representative will contact you
          to confirm your registration and provide additional information about
          the event.
        </p>
        <form onSubmit={handleSubmit} className="ozslot-contact__form">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Your name"
            className="ozslot-form__input t-22"
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Your emil address"
            className="ozslot-form__input t-22"
          />
          <input
            name="phone"
            type="tel"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Your phone"
            className="ozslot-form__input t-22"
          />
          <button type="submit" className="ozslot-form__btn t-24">
            Send
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
