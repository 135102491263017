import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import age from "../../images/age.svg";
import inst from "../../images/instagram.svg";
import telegram from "../../images/telegram.svg";

const Footer = () => {
  return (
    <footer className="ozslot-footer">
      <div className="ozslot-footer__support">
        <p className="t-22 text-center col-white">Support service</p>
        <Link
          to="mailto:au-ozslotkings@gmail.com"
          className="ozslot-footer__mail t-18 ozslot-footer__link col-white"
          target="_blank"
        >
          au-ozslotkings@gmail.com
        </Link>
      </div>
      <div className="ozslot-footer__text">
        <p className="t-18 text-center col-white">
          “au-ozslotkings.com” - uses cookies to ensure you get the best
          possible experience. If you stay on the site, you agree to our use of
          your cookies.
        </p>
        <Link
          to="/cookies"
          className="ozslot-footer__cookies ozslot-footer__link t-18 col-white"
        >
          Read more
        </Link>
      </div>
      <div className="ozslot-footer__links">
        <img
          src={age}
          alt="age icon 18+"
          title="age icon 18+"
          width="65"
          height="65"
        />
        <Link to="/privacy" className="ozslot-footer__privacy col-white t-22">
          Privacy policy
        </Link>
        <div className="ozslot-footer__social">
          <a href="#">
            <img
              src={inst}
              alt="instagram icon"
              title="instagram icon"
              width="38"
              height="38"
            />
          </a>
          <a href="#">
            <img
              src={telegram}
              alt="telegram icon"
              title="telegram icon"
              width="38"
              height="38"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
